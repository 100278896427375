import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Confirm } from 'src/app/types/global'

@Component({
  selector: 'kabin-confirm',
  templateUrl: './confirm.page.html',
  styleUrls: ['./confirm.page.scss'],
})
export class ConfirmPage implements OnInit {

  @Input() confirm: Confirm;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  answer(action: boolean) {
    this.modalCtrl.dismiss({ dismissed: true, action });
  }

}
