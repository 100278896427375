export const environment = {
  production: true,
  debug: true,
  apiUrl: 'https://api.payment.kabin.fr/api/',
  frontVersion: '2.0.0',
  apiKey: 'AIzaSyDeOs20CQPhZYjn2VfYn2WdhDHKUsYUcgM',
  stripePublicKey: 'pk_test_51HyKAtC09bJzn8ZVC4iLpKu0loqfC92nMT1a1QzFzf3TVtMjsJ0M3qyyvlo9Y59H2LJzZ0k43JdPxYTAuiVOwxiT00GThBtG7f',
  pusher: {
    host: 'soketi.webrocks.net',
    port: 443,
    scheme: 'https',
    app_id: 'kabin',
    key: 'b90e8a0c-e604-40e6-917e-fd3e336aec92',
    secret: 'ec02fc4f-0a23-4db3-b77a-59d0bf6dac3a',
    cluster: 'mt1'
  }
};
