import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { ConfirmPage } from 'src/app/modals/confirm/confirm.page';

import { Confirm } from 'src/app/types/global'

@Component({
  selector: 'kabin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class KabinHeaderComponent implements OnInit {

  @Input() action: string = 'menu';
  @Input() color: string = 'primary';
  @Input() confirm: Confirm = null;
  @Input() noShadow: boolean = false;
  @Input() redirectData: {
    url: string,
    extras?: NavigationExtras
  } = null;
  @Output() onClick = new EventEmitter<void>();

  constructor(
    public router: Router,
    public location: Location,
    public modalCtrl: ModalController,
    private menu: MenuController,
    public navController: NavController
  ) { }

  ngOnInit() {
  }

  switchAction() {
    switch (this.action) {
      case 'goback':
        this.navController.back();
        break;
      case 'dismiss':
        this.modalCtrl.dismiss({ dismissed: true });
        break;
      case 'menu':
        this.menu.open('main-content');
        break;
      case 'close':
        this.navController.navigateRoot(['/index'], {
          animated: false,
          replaceUrl: true
        });
        break;
      case 'back':
        this.navController.navigateBack('', {
          animated: false
        });
        break;
      case 'dismiss-close':
        this.navController.navigateRoot(['/index'], {
          replaceUrl: true,
          animated: false
        });
        this.modalCtrl.dismiss({ dismissed: true });
        break;
      case 'dismiss-landing':
        this.navController.navigateRoot(['/landing'], {
          animated: false,
          replaceUrl: true
        })
        this.modalCtrl.dismiss({
          dismissed: true
        })
        break;
      case 'dismiss-finish':
        this.modalCtrl.dismiss({ dismissed: true, finished: true });
        break;
      case 'redirect':
        if (this.redirectData) {
          this.router.navigate([this.redirectData.url], this.redirectData.extras)
        }
        break;
      default:
        this.navController.navigateRoot(['/index'], {
          replaceUrl: true,
          animated: false
        });
    }
  }

  async actionOnButton() {
    this.onClick.emit()

    if (!this.confirm) {
      this.switchAction()
    } else {

      const modal = await this.modalCtrl.create({
        component: ConfirmPage,
        componentProps: {
          confirm: this.confirm
        }
      })

      modal.onDidDismiss().then(data => {
        if (data?.data.action) {
          this.switchAction()
        }
      });
      modal.present();
    }
  }
}
