import { 
    SET_LOCALIZATION, 
    setLocalization,
    setLocalizationFailure,
    GET_LOCALIZATION,
    getLocalizationSuccess,
    getLocalizationFailure,
    setLocalizationSuccess,
    GET_LOCALIZATION_LIST,
    getLocalizationListFailure,
    getLocalizationListSuccess,
    GET_LOCALIZATION_USER,
    getLocalizationUserSuccess,
    getLocalizationUserFailure,
    SET_LOCALIZATION_USER,
    setLocalizationUserSuccess,
    setLocalizationUserFailure,
} from "../actions/localization.actions";
import { LocalizationService } from "src/app/services/localization.service";
import { selectLocalization } from "../selectors/localization.selectors";
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect,  } from '@ngrx/effects';
import { of, from, Observable } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from "..";
import { Action } from "rxjs/internal/scheduler/Action";
import { selectConnectionStatus } from "../selectors/auth.selectors";
import { LocalizationResponse } from "src/app/types/global";

@Injectable()
export class LocalizationEffects {

  getLocalization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_LOCALIZATION),
      exhaustMap(() => {
        return this.localizationService.getLocalization().pipe(
          map((localization: LocalizationResponse) => getLocalizationSuccess({ localization })),
          catchError(error => of(getLocalizationFailure(error)))
        )
      })
    )
  );

  getLocalizationUser$ = createEffect(() => 
    this.actions$.pipe(
      ofType(GET_LOCALIZATION_USER),
      exhaustMap(() => {
        return this.localizationService.getLocalizationUser().pipe(
          map((localization: LocalizationResponse) => getLocalizationUserSuccess({ localization })),
          catchError(error => of(getLocalizationUserFailure(error)))
        )
      })
    )
  );

  setLocalization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_LOCALIZATION),
      exhaustMap(({ lang }: { lang: string }) => 
        this.localizationService.setLocalization(lang).pipe(
          map((localization: LocalizationResponse) => setLocalizationSuccess({ localization })),
          catchError(error => of(setLocalizationFailure(error)))
        )
      )
    )
  );

  setLocalizationUser$ = createEffect(() => 
    this.actions$.pipe(
      ofType(SET_LOCALIZATION_USER),
      exhaustMap(({ lang }: { lang: string }) => 
        this.localizationService.setLocalizationUser(lang).pipe(
          map((localization: LocalizationResponse) => setLocalizationUserSuccess({ localization })),
          catchError(error => of(setLocalizationUserFailure(error)))
        )
      )
    )
  );

  getLocalizationList$ = createEffect(() => 
    this.actions$.pipe(
      ofType(GET_LOCALIZATION_LIST),
      withLatestFrom(this.store$),
      exhaustMap(() => {
        return this.localizationService.getLocalizationList().pipe(
          map((localizationList: Array<string>) => getLocalizationListSuccess({ localizationList })),
          catchError(error => of(getLocalizationListFailure(error)))
        )
      })
    )
  );

  constructor(
    private actions$: Actions,
    private localizationService: LocalizationService,
    private store$: Store<AppState>,
  ) { }
}