import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, take } from 'rxjs/operators';
import { CountryCode, parsePhoneNumberWithError, NationalNumber } from 'libphonenumber-js/max';
import { Store } from '@ngrx/store';
import { registerSelectCountry, sendCode } from 'src/app/store/actions/auth.actions';
import { Observable } from 'rxjs';
import { selectAuthError, selectAuthRegisterCountry } from 'src/app/store/selectors/auth.selectors';
import { Country } from 'src/app/store/reducers/auth.reducer';
import { default as countries } from 'src/app/utils/countries-phone.json'
import { selectLocalization } from 'src/app/store/selectors/localization.selectors';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'kabin-register-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent  implements OnInit {

  phoneForm: UntypedFormGroup = this.formBuilder.group({
    phone: ['', [Validators.required, Validators.pattern(/^[0-9\s]*$/)]],
  });
  countries: Country[];
  errorMessage = '';
  country$: Observable<Country>;
  error$: Observable<any>;
  language: string = 'fr';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private translocoService: TranslocoService,
  ) { 
    this.country$ = this.store.select(selectAuthRegisterCountry);
    this.error$ = this.store.select(selectAuthError);
    if (translocoService.getActiveLang()) {
      this.countries = countries[translocoService.getActiveLang()]
    }
    this.store.select(selectLocalization).subscribe(lang => {
      this.language = lang
    })
  }

  ngOnInit() {
  }

  selectCountry({ target }) {
    this.store.dispatch(registerSelectCountry({ country: this.countries.find((country: Country) => country.code === target.value) }))
  }

  validatePhoneCode() {
    const phone = this.validatePhoneNumber();
    if (phone) {
      this.store.dispatch(sendCode({ phone }));
    }
  }

  validatePhoneNumber() {
    try {
      let selectedCountry;
      this.country$.pipe(take(1)).subscribe((country: Country) => {
        selectedCountry = country;
      })
      const phoneNumber = parsePhoneNumberWithError(this.phoneForm.controls.phone.value, selectedCountry.code);
      if (phoneNumber.getType() !== 'MOBILE') {
        if (this.language === 'fr') {
          this.errorMessage = "Veuillez indiquer un numéro de téléphone portable.";
        } else {
          this.errorMessage = "Please enter a phone number."
        }
        return false;
      }
      if (!phoneNumber.isValid()) {
        if (this.language === 'fr') {
          this.errorMessage = 'Ce numéro de téléphone n\'est pas valide.';
        } else {
          this.errorMessage = "This number is invalid.";
        }
        return false;
      }
      return phoneNumber.nationalNumber;
    } catch ({ message }) {
      switch(message) {
        case 'TOO_SHORT':
          if (this.language === 'fr') {
            this.errorMessage = 'Numéro trop court.';
          } else {
            this.errorMessage = "Your number is too short.";
          }
          return false;
        case 'TOO_LONG':
          if (this.language === 'fr') {
            this.errorMessage = 'Numéro trop long.';
          } else {
            this.errorMessage = "Your number is too long.";
          }
          return false;
      }
      console.log('error', message);
    }
  }

  hideAlert(event: any, prop: string) {
    if (event) this[prop] = null
  }

}
