import { createReducer, on } from "@ngrx/store"
import {
    setLocalizationFailure,
    setLocalizationSuccess,
    getLocalizationFailure,
    getLocalizationSuccess,
    getLocalizationListSuccess,
    getLocalizationListFailure,
    getLocalizationUserSuccess,
    setLocalizationUserSuccess,
    setLocalizationUserFailure,
    getLocalizationUserFailure,
} from "../actions/localization.actions";
import { LocalizationResponse } from "src/app/types/global";

export interface LocalizationState {
    localization: any;
    localizationList: Array<string>;
    error: any;
}

export const initialState: LocalizationState = {
    localization: {},
    localizationList: [],
    error: null,
}

const onFailure = (state: LocalizationState, { error }) => {
    return {
        ...state,
        localization: {},
        error,
    }
}
const onSuccess = (state: LocalizationState, { localization }): LocalizationState => {
    return {
        ...state,
        localization,
        error: null
    }
}

const onLocalizationSuccess = (state: LocalizationState, { localization }: { localization: LocalizationResponse} ): LocalizationState => {
    return {
        ...state,
        localization
    }
}

const onGetLocalizationListSuccess = (state: LocalizationState, { localizationList }: { localizationList: Array<string> }): LocalizationState => {
    return {
        ...state,
        localizationList
    }
}

const onGetLocalizationListFailure = (state: LocalizationState, { error }) => {
    return {
        ...state,
        localizationList: [],
        error
    }
}

export const reducer = createReducer(
    initialState,
    on(
        setLocalizationSuccess,
        setLocalizationUserSuccess,
        onSuccess
    ),
    on(
        getLocalizationSuccess,
        getLocalizationUserSuccess,
        onLocalizationSuccess
    ),
    on(
        getLocalizationListSuccess,
        onGetLocalizationListSuccess
    ),
    on(
        getLocalizationListFailure,
        onGetLocalizationListFailure
    ),
    on(
        getLocalizationFailure,
        getLocalizationUserFailure,
        setLocalizationFailure,
        setLocalizationUserFailure,
        onFailure
    ),
)