import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, tap } from "rxjs/operators";
import { BookingService } from "src/app/services/booking.service";
import {
  LOAD_NEXT_BOOKINGS,
  LOAD_PAST_BOOKINGS,
  loadNextBookingsSuccess,
  loadNextBookingsFailure,
  loadPastBookingsSuccess,
  loadPastBookingsFailure,
  CANCEL_BOOKING,
  cancelBookingSuccess,
  cancelBookingFailure,
  CANCEL_BOOKING_SUCCESS,
  LOAD_PAYMENT_METHODS,
  loadPaymentMethodsFailure,
  loadPaymentMethodsSuccess,
  LOAD_BILLING_ADDRESSES,
  loadBillingAddressesSuccess,
  loadBillingAddressesFailure,
  LOAD_BILLING_ADDRESSES_SUCCESS,
  LOAD_PAYMENT_METHODS_SUCCESS,
} from "../actions/me.actions";
import { MeService } from "src/app/services/me.service";
import { Booking } from "src/app/types/booking";
import { NotificationService } from "src/app/services/notification.service";
import { Router } from "@angular/router";
import { BillingAddress, PaymentMethod } from "src/app/types/payment";
import { setSelectedBillingAddress, setSelectedPaymentMethod } from "../actions/booking.actions";

@Injectable()
export class MeEffects {
  loadNextBookings$ = createEffect(() => 
    this.actions$.pipe(
      ofType(LOAD_NEXT_BOOKINGS),
      exhaustMap(() => 
        this.meService.getNextBookings().pipe(
          map((bookings: any) => loadNextBookingsSuccess({ bookings })),
          catchError((error: any) => of(loadNextBookingsFailure({ error }))),
        )
      )
    )
  );

  loadPastBookings$ = createEffect(() => 
    this.actions$.pipe(
      ofType(LOAD_PAST_BOOKINGS),
      exhaustMap(() => 
        this.meService.getPastBookings().pipe(
          map((bookings: any) => loadPastBookingsSuccess({ bookings })),
          catchError((error: any) => of(loadPastBookingsFailure({ error }))),
        )
      )
    )
  );

  cancelBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CANCEL_BOOKING),
      exhaustMap(({ booking }: { booking: Booking }) =>
        this.bookingService.cancel(booking).pipe(
          map(() => cancelBookingSuccess({ booking })),
          catchError((error: any) => of(cancelBookingFailure({ error }))),
        )
      )
    )
  );

  canceledBookingDismissModal = createEffect(() => {
    return this.actions$.pipe(
      ofType(CANCEL_BOOKING_SUCCESS),
      tap(() => {
        this.router.navigate(['/bookings'])
        this.notificationService.notify({
          title: 'Annulation de résérvation',
          body: 'L\'annulation de votre réservation à bien été prise en compte',
        });
      })
    )
  }, {
    dispatch: false,
  })

  loadPaymentMethods$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_PAYMENT_METHODS),
      exhaustMap(() => {
        return this.meService.getPaymentMethods().pipe(
          map((paymentMethods: PaymentMethod[]) => loadPaymentMethodsSuccess({ paymentMethods })),
          catchError(error => of(loadPaymentMethodsFailure(error))),
        );
      })
    );
  });

  loadBillingAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_BILLING_ADDRESSES),
      exhaustMap(() => {
        return this.meService.getBillingAddresses().pipe(
          map((billingAddresses: BillingAddress[]) => loadBillingAddressesSuccess({ billingAddresses })),
          catchError(error => of(loadBillingAddressesFailure(error))),
        );
      })
    );
  });

  // loadBillingAddressesSetSelected$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(LOAD_BILLING_ADDRESSES_SUCCESS),
  //     filter(({ billingAddresses }: { billingAddresses: BillingAddress[]}) => billingAddresses.length > 0),
  //     map(({ billingAddresses }: { billingAddresses: BillingAddress[]}) => {
  //       return setSelectedBillingAddress({
  //         selected: billingAddresses.find((address: BillingAddress) => address.is_default) ?? billingAddresses.length ? billingAddresses[0] : null
  //       })
  //     })
  //   );
  // });

  // loadPaymentMethodsSetSelected$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(LOAD_PAYMENT_METHODS_SUCCESS),
  //     filter(({ paymentMethods }: { paymentMethods: PaymentMethod[]}) => paymentMethods.length > 0),
  //     map(({ paymentMethods }: { paymentMethods: PaymentMethod[]}) => {
  //       return setSelectedPaymentMethod({
  //         selected: paymentMethods.find((address: PaymentMethod) => address.is_default) ?? paymentMethods.length ? paymentMethods[0] : null
  //       })
  //     })
  //   );
  // });


  constructor(
    private actions$: Actions,
    private bookingService: BookingService,
    private meService: MeService,
    private notificationService: NotificationService,
    private router: Router,
  ) { }
}
