import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {

    constructor(
        private httpClient: HttpClient
    ) { }

    getLocalizationList(): Observable<any> {
        return this.httpClient.get(environment.apiUrl + 'locale');
    }

    getLocalization(): Observable<any> {
        return this.httpClient.get(environment.apiUrl + 'locale/localization');
    }

    getLocalizationUser(): Observable<any> {
        return this.httpClient.get(environment.apiUrl + 'me/profile/lang');
    }

    setLocalization(locale: string = 'fr', data: any = {}): Observable<any> {
        return this.httpClient.post(environment.apiUrl + `locale/${locale}`, data);
    }

    setLocalizationUser(locale: string = 'fr', data: any = {}): Observable<any> {
        return this.httpClient.post(environment.apiUrl + `me/profile/lang/${locale}`, data);
    }
}