import { Component, OnInit, OnDestroy } from '@angular/core';
import { Booking } from 'src/app/types/booking';
import { User } from 'src/app/types/auth';
import { Store } from '@ngrx/store';
import { selectConnectedUser } from 'src/app/store/selectors/auth.selectors';
import { PodStatus } from 'src/app/types/pod';
import { RideCheck } from 'src/app/types/ride';
import { selectBookingStatus, selectCurrentBooking, selectDoorAndPresenceStatus, selectPodStatus } from 'src/app/store/selectors/ride.selectors';
import { BehaviorSubject, Observable, Subscription, combineLatest, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map, skipUntil, take, takeWhile, withLatestFrom } from 'rxjs/operators';
import { PusherService } from 'src/app/services/pusher.service';
import { completeCheckinSuccess, getPodStatusSuccess, loadCurrentBooking, setBookingStatus } from 'src/app/store/actions/ride.actions';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'kabin-ride',
  templateUrl: './ride.page.html',
  styleUrls: ['./ride.page.scss'],
})
export class RidePage implements OnInit, OnDestroy {

  booking$: Observable<Booking>;
  podStatus$: Observable<any>
  bookingStatus$: Observable<string>
  public user: User;
  public startChecks: RideCheck[];
  public endChecks: RideCheck[];
  isInAutomaticBuffer$ = new BehaviorSubject<boolean>(false);

  subscriptions$: Subscription
  constructor(
    private store: Store,
    private pusherService: PusherService,
    private modalController: ModalController,
  ) { 
    this.store.select(selectConnectedUser).subscribe((user: User) => {
      this.user = user;
    })
    this.booking$ = this.store.select(selectCurrentBooking)
    this.startChecks = [{
      key: 'door',
      icon: 'lock-closed-outline',
      value: false,
      labelTranslate: 'ride.checks.start.door',
    }, {
      key: 'seat',
      icon: 'body-outline',
      value: false,
      labelTranslate: 'ride.checks.start.seat',
    }];
    this.endChecks = [{
      key: 'door',
      icon: 'lock-closed-outline',
      value: true,
      labelTranslate: 'ride.checks.end.door',
    }, {
      key: 'seat',
      icon: 'body-outline',
      value: false,
      labelTranslate: 'ride.checks.end.seat',
    }];
    this.booking$ = this.store.select(selectCurrentBooking)
    this.podStatus$ = this.store.select(selectDoorAndPresenceStatus)
    this.bookingStatus$ = this.store.select(selectBookingStatus)
    this.subscriptions$ = new Subscription()

    const bookingForPusher = this.booking$
      .pipe(distinctUntilChanged())
      .subscribe((booking: Booking) => {
        const channel = this.pusherService.subscribe(`pod.${booking.pod.uuid}`)
        channel.bind('new-booking', (x: any) => {
          this.store.dispatch(loadCurrentBooking());
        })
        channel.bind('booking-canceled', (x: any) => {
          this.store.dispatch(loadCurrentBooking());
        })
      });
    this.subscriptions$.add(bookingForPusher);
  }

  ngOnInit() {
    combineLatest([
      this.bookingStatus$,
      this.podStatus$
    ]).pipe(
      filter(([bookingStatus]: [string, PodStatus]) => bookingStatus === 'pre-ride'),
      filter(([, podStatus]: [string, PodStatus]) => podStatus !== null)
    ).subscribe(([, podStatus]: [string, PodStatus]) => {
      if (podStatus.door && podStatus.presence === 'busy') {
        this.store.dispatch(setBookingStatus({ status: 'ride' }))
      }
      this.startChecks = [
        {
          key: 'door',
          icon: 'lock-closed-outline',
          value: podStatus.door,
          labelTranslate: 'ride.checks.start.door',
        },
        {
          key: 'seat',
          icon: 'body-outline',
          value: podStatus.presence === 'free' ? false : true,
          labelTranslate: 'ride.checks.start.seat',
        }
      ];
    })
    const dismissBookingWhenNull = this.booking$.subscribe((booking: Booking) => {
      if (booking === null) {
        this.modalController.dismiss()
      }
    })
    this.subscriptions$.add(dismissBookingWhenNull);
  }

  ngOnDestroy(): void {
    const bookingOnDestroy = this.booking$
      .pipe(take(1))
      .subscribe((booking: Booking) => {
        this.pusherService.unsubscribe(`pod.${booking.pod.uuid}`);
      });
    this.subscriptions$.add(bookingOnDestroy);
    this.subscriptions$.unsubscribe()
    // this.pusherService.unsubscribe(`pod.${this.booking.pod.uuid}.status`) TODO: rebuild unsubscription
  }

  onStartCheckDone() {
    console.log('onStartCheckDone')
  }

  onEndCheckDone() {
    console.log('onEndCheckDone')
  }
}