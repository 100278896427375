import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePipe } from './time/time.pipe';
import { DateFnsModule } from 'ngx-date-fns';
import { TruncatePipe } from './truncate/truncate.pipe';
import { MinutesToHoursPipe } from './time/minutes-to-hours.pipe';

@NgModule({
  declarations: [
    TimePipe,
    TruncatePipe,
    MinutesToHoursPipe,
  ],
  exports: [
    TimePipe,
    DateFnsModule,
    TruncatePipe,
    MinutesToHoursPipe,
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
