import { HttpErrorResponse } from "@angular/common/http";
import { createReducer, on } from "@ngrx/store";
import { Booking } from "src/app/types/booking";
import {
  clearRide,
  completeCheckin,
  completeCheckinFailure,
  completeCheckinSuccess, 
  completeCheckout,
  completeCheckoutFailure,
  completeCheckoutSuccess,
  completeRide,
  completeRideFailure,
  completeRideSuccess,
  extendBooking,
  extendBookingFailure,
  extendBookingSuccess,
  getPodStatusSuccess,
  loadCurrentBooking,
  loadCurrentBookingFailure,
  loadCurrentBookingSuccess,
  openKabin,
  openKabinFailure,
  openKabinSuccess,
  setBookingStatus
} from "../actions/ride.actions";
import { PodStatus } from "src/app/types/pod";

export interface RideState {
  booking: Booking,
  podStatus: PodStatus,
  error: any,
  loading: boolean,
}

export const initialState: RideState = {
  booking: null,
  podStatus: null,
  error: null,
  loading: false,
};

const onFailure = (state: RideState, { error }): RideState => {
  return {
    ...state,
    error,
    loading: false,
  }
}

const onSuccess = (state: RideState): RideState => {
  return {
    ...state,
    error: null,
    loading: false,
  }
}

const onLoading = (state: RideState): RideState => {
  return {
    ...state,
    error: null,
    loading: true,
  }
}

const onOpenKabinSuccess = (state: RideState, { booking }: { booking: Booking }): RideState => {
  return {
    ...state,
    booking: {
      ...booking,
      ...state.booking,
      status: booking.status,
    },
  }
}

const onCompleteCheckinSuccess = (state: RideState, { booking }: { booking: Booking}): RideState => {
  return {
    ...state,
    booking: {
      ...booking,
      ...state.booking,
      status: booking.status,
    },
  }
}

const onCompleteRideSuccess = (state: RideState, { status }: { status: string }): RideState => {
  return {
    ...state,
    booking: {
      ...state.booking,
      status,
    }
  }
}

const onCompleteCheckoutSuccess = (state: RideState, { booking }: { booking: Booking }): RideState => {
  return {
    ...state,
    booking: {
      ...state.booking,
      status: booking.status,
    }
  }
}

const onLoadCurrentBookingSuccess = (state: RideState, { booking }: { booking: Booking }): RideState => {
  return {
    ...state,
    booking,
  }
}

const onGetPodStatusSuccess = (state: RideState, { status }: { status: PodStatus}): RideState => {
  return {
    ...state,
    podStatus: status,
  }
}

const onSetBookingStatus = (state: RideState, { status }: { status: string}): RideState => {
  return {
    ...state,
    booking: state.booking ? {
      ...state.booking,
      status,
    }: null,
  }
}

const onExtendBookingSuccess = (state: RideState, { booking }: { booking: Booking }): RideState => {
  return {
    ...state,
    booking: {
      ...state.booking,
      count: booking.count,
      dynamic_buffer_end_at: booking.dynamic_buffer_end_at,
      automatic_buffer_end_at: booking.automatic_buffer_end_at,
    },
  }
}

const onClearRide = (state: RideState): RideState => {
  return {
    ...initialState
  }
}

export const reducer = createReducer(
  initialState,
  on(
    getPodStatusSuccess,
    onGetPodStatusSuccess
  ),
  on(
    openKabinSuccess,
    onOpenKabinSuccess
  ),
  on(
    completeCheckinSuccess,
    onCompleteCheckinSuccess
  ),
  on(
    completeRideSuccess,
    onCompleteRideSuccess
  ),
  on(
    completeCheckoutSuccess,
    onCompleteCheckoutSuccess
  ),
  on(
    setBookingStatus,
    onSetBookingStatus
  ),
  on(
    loadCurrentBookingSuccess,
    onLoadCurrentBookingSuccess,
  ),
  on(
    extendBookingSuccess,
    onExtendBookingSuccess,
  ),
  on(
    clearRide,
    onClearRide,
  ),
  on(
    openKabin,
    completeCheckin,
    completeRide,
    completeCheckout,
    loadCurrentBooking,
    extendBooking,
    onLoading
  ),
  on(
    setBookingStatus,
    openKabinSuccess,
    completeCheckinSuccess,
    completeRideSuccess,
    completeCheckoutSuccess,
    loadCurrentBookingSuccess,
    extendBookingSuccess,
    onSuccess
  ),
  on(
    openKabinFailure,
    completeCheckinFailure,
    completeRideFailure,
    completeCheckoutFailure,
    loadCurrentBookingFailure,
    extendBookingFailure,
    onFailure
  ),
);
