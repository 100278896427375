import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { resendCode, verifyCode } from 'src/app/store/actions/auth.actions';
import { selectAuthError, selectAuthLoading } from 'src/app/store/selectors/auth.selectors';

@Component({
  selector: 'kabin-register-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
})
export class CodeComponent  implements OnInit {

  
  code: string = '';
  alert: boolean = false;
  alertType: string = 'error';
  countdown: number = 0;
  error$: Observable<any>;
  loading$: Observable<boolean>;

  constructor(
    private store: Store,
  ) { 
    this.error$ = this.store.select(selectAuthError);
    this.loading$ = this.store.select(selectAuthLoading);
  }

  ngOnInit() {
    setInterval(() => {
      if (this.countdown !== 0) {
        this.countdown--;
      }
    }, 1000)
  }

  resendCode() {
    this.countdown = 15;
    this.store.dispatch(resendCode());
    this.alertType = 'success'
    this.alert = true;
  }

  codeEntered() {
    if (this.code.length === 4) {
      this.store.dispatch(verifyCode({ code: this.code }));
    }
  }

  hideAlert(event: any, prop: string) {
    if (event) {
      this[prop] = null;
    }
  }

}
