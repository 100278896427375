import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';

import { default as passwordStrengh } from 'src/app/utils/password-strengh.json'

@Component({
  selector: 'kabin-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit {

  @Input() password: string

  public passwordStrengh = passwordStrengh.fr
  public passwordMeter = 0

  constructor(
    private store: Store,
    private translocoService: TranslocoService,
  ) {
    this.passwordStrengh = passwordStrengh[translocoService.getActiveLang()]
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    let currentPassword = changes.password.currentValue || ''
    let lengthRegex = /^(?=.{5,}).*$/
    let majRegex = /^(?=.*[A-Z]).*$/
    let numberRegex = /^(?=.*[0-9]).*$/
    let specialRegex = /^(?=.*[@#,$*%\-\._:\"!?\'(){}[\];^&+=]).*$/
    this.passwordMeter = 0;

    if (currentPassword.length) {
      if (currentPassword.match(lengthRegex)) {
        if (currentPassword.match(majRegex)) {
          this.passwordMeter++;
        }
        if (currentPassword.match(numberRegex)) {
          this.passwordMeter++;
        }
        if (currentPassword.match(specialRegex)) {
          this.passwordMeter++;
        }
        if (this.passwordMeter === 0) {
          this.passwordMeter = 1;
        }
      } else {
        console.log('no length')
        this.passwordMeter = 1;
      }
    } else {
      this.passwordMeter = 0;
    }
  }
}
