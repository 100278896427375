import { createReducer, on } from "@ngrx/store";
import { Booking } from "src/app/types/booking";
import {
  loadNextBookings,
  loadNextBookingsSuccess,
  loadNextBookingsFailure,
  loadPastBookings,
  loadPastBookingsSuccess,
  loadPastBookingsFailure,
  cancelBookingSuccess,
  cancelBooking,
  cancelBookingFailure,
  clearMeError,
  loadPaymentMethodsSuccess,
  loadBillingAddressesSuccess,
  loadPaymentMethods,
  loadBillingAddresses,
  loadPaymentMethodsFailure,
  loadBillingAddressesFailure,
} from "../actions/me.actions";
import { BillingAddress, PaymentMethod } from "src/app/types/payment";

export interface MeState {
  bookings: {
    next: Booking[],
    past: Booking[],
  },
  paymentMethods: PaymentMethod[],
  billingAddresses: BillingAddress[],
  error: any,
  loading: boolean,
}

export const initialState: MeState = {
  bookings: {
    next: [],
    past: [],
  },
  paymentMethods: [],
  billingAddresses: [],
  error: null,
  loading: false,
};

const onLoading = (state: MeState): MeState => {
  return {
    ...state,
    loading: true,
  }
}

const onFailure = (state: MeState, { error }): MeState => {
  return {
    ...state,
    loading: false,
    error,
  }
}

const onSuccess = (state: MeState): MeState => {
  return {
    ...state,
    loading: false,
    error: null,
  }
}

const onLoadNextBookingsSuccess = (state: MeState, { bookings }: { bookings: Booking[] }) => {
  return {
    ...state,
    bookings: {
      ...state.bookings,
      next: bookings
    }
  }
}

const onLoadPastBookingsSuccess = (state: MeState, { bookings }: { bookings: Booking[] }) => {
  return {
    ...state,
    bookings: {
      ...state.bookings,
      past: bookings
    }
  }
}

const onCancelBookingSuccess = (state: MeState, { booking }) => {
  return {
    ...state,
    bookings: {
      ...state.bookings,
      next: state.bookings.next.filter((item: Booking) => booking.uuid !== item.uuid)
    },
  }
}

const onLoadPaymentMethodsSuccess = (state: MeState, { paymentMethods }: { paymentMethods: PaymentMethod[] }): MeState => {
  return {
    ...state,
    paymentMethods,
  }
}

const onLoadBillingAddressesSuccess = (state: MeState, { billingAddresses }: { billingAddresses: BillingAddress[] }): MeState => {
  return {
    ...state,
    billingAddresses,
  }
}


const onClearMeError = (state: MeState) => {
  return {
    ...state,
    error: null,
  }
}

export const reducer = createReducer(
  initialState,
  on(loadNextBookingsSuccess, onLoadNextBookingsSuccess),
  on(loadPastBookingsSuccess, onLoadPastBookingsSuccess),
  on(cancelBookingSuccess, onCancelBookingSuccess),
  on(clearMeError, onClearMeError),
  on(
    loadPaymentMethodsSuccess,
    onLoadPaymentMethodsSuccess
  ),
  on(
    loadBillingAddressesSuccess,
    onLoadBillingAddressesSuccess
  ),
  on(
    loadNextBookings,
    loadPastBookings,
    cancelBooking,
    loadPaymentMethods,
    loadBillingAddresses,
    onLoading
  ),
  on(
    loadNextBookingsSuccess,
    loadPastBookingsSuccess,
    cancelBookingSuccess,
    loadBillingAddressesSuccess,
    loadPaymentMethodsSuccess,
    onSuccess
  ),
  on(
    loadNextBookingsFailure,
    loadPastBookingsFailure,
    cancelBookingFailure,
    loadPaymentMethodsFailure,
    loadBillingAddressesFailure,
    onFailure
  ),
);

