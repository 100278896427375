import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { enGB, fr } from 'date-fns/locale';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { Observable } from 'rxjs';
import { setLocalization, setLocalizationUser } from 'src/app/store/actions/localization.actions';
import { selectConnectedUser } from 'src/app/store/selectors/auth.selectors';
import { selectLocale, selectLocalizationList } from 'src/app/store/selectors/localization.selectors';

@Component({
  selector: 'kabin-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss'],
})
export class LocalizationComponent implements OnInit {

  languages$: Observable<Array<string>>;
  locale$: Observable<string>;

  constructor(
    public store: Store,
    public config: DateFnsConfigurationService
  ) { }

  ngOnInit() {
    this.languages$ = this.store.select(selectLocalizationList);
    this.locale$ = this.store.select(selectLocale);
    this.store.select(selectLocale).subscribe(locale => {
      this.switchDateFNSConfig(locale)
    })
  }

  switchDateFNSConfig(lang: string) {
    switch (lang) {
      case "en":
        this.config.setLocale(enGB);
        break;
      case "fr":
        this.config.setLocale(fr);
        break;
      default: this.config.setLocale(fr)
    }
  }

  setLanguage(lang: string) {
    this.store.select(selectConnectedUser).subscribe((user) => {
      if (user) {
        this.store.dispatch(setLocalizationUser({lang}))
      } else {
        this.store.dispatch(setLocalization({lang}));
      }
    })
    this.switchDateFNSConfig(lang)
  }

}
