import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController, IonSlides, ModalController } from '@ionic/angular';
import {
  loadStripe,
  PaymentMethod,
  SetupIntent,
  Stripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement
} from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';
import { MeService } from 'src/app/services/me.service';
import { Store } from '@ngrx/store';
import { loadPaymentMethods } from 'src/app/store/actions/me.actions';
@Component({
  selector: 'kabin-add',
  templateUrl: './add.page.html',
  styleUrls: ['./add.page.scss'],
})
export class AddPage implements OnInit {

  @Input() fromModal: boolean = false

  @ViewChild('slider', { static: true }) slider: IonSlides;
  loading = false;
  formBilling: UntypedFormGroup = this.formBuilder.group({
    name: ['', [Validators.maxLength(20)]]
  });
  stripe: Stripe;
  cardNumber: StripeCardNumberElement;
  expiry: StripeCardExpiryElement;
  cvc: StripeCardCvcElement;
  cardNumberEl: any = {};
  expiryEl: any = {};
  cvcEl: any = {};
  error: string;
  slideOpts: any = {
    initialSlide: 0,
    speed: 400,
  };
  selection: any = null;
  step = 0;
  action: string = 'back'

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private meService: MeService,
    private modalCtrl: ModalController,
    private router: Router,
    private navController: NavController,
    private store: Store,
  ) { }

  async ngOnInit() {
    if (this.fromModal) {
      this.action = 'dismiss'
    }
    this.stripe = await loadStripe(environment.stripePublicKey);
    this.setupStripe();
  }

  async addPaymentMethod() {
    this.loading = true;
    const { paymentMethod } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardNumber,
      metadata: {
        name: this.formBilling.value.name
      }
    })
    this.meService.addPaymentMethod({ pm_id: paymentMethod.id }).pipe(
      finalize(() => this.loading = false)
    ).subscribe(
      async (setupIntent: SetupIntent) => {
        if (setupIntent.status === 'requires_action') {
          try {
            await this.stripe.confirmCardSetup(setupIntent.client_secret)
          } catch (error) {
            // TODO: handle error
            console.log('Error : ', error);
          }
        }
        this.store.dispatch(loadPaymentMethods())
        this.goBack();
      },
      (error) => {
        //TODO: handle error
        console.log('Error : ', error);
      }
    )
  }

  goBack() {
    if (this.fromModal) {
      this.modalCtrl.dismiss({ dismissed: true });
    } else {
      this.navController.back();
    }
  }

  setupStripe() {
    const elements = this.stripe.elements();
    this.cardNumber = elements.create('cardNumber');
    this.cardNumber.mount('#number');
    this.expiry = elements.create('cardExpiry');
    this.expiry.mount('#expiry');
    this.cvc = elements.create('cardCvc');
    this.cvc.mount('#cvc');
    this.cardNumber.on('change', (event: any) => {
      this.setOutcome(event);
    });
    this.expiry.on('change', (event: any) => {
      this.setOutcome(event);
    });
    this.cvc.on('change', (event: any) => {
      this.setOutcome(event);
    });
  }

  setOutcome(result: any) {
    switch (result.elementType) {
      case 'cardNumber':
        this.cardNumberEl = result;
        break;
      case 'cardExpiry':
        this.expiryEl = result;
        break;
      case 'cardCvc':
        this.cvcEl = result;
        break;
    }
    this.cdr.detectChanges();
  }

  onSuggestion(selection: any) {
    this.selection = selection;
  }
  onClear($event: any) {
    this.selection = null;
  }

  hideAlert(event: any) {
    if (event) {
      this.error = null
      this.cardNumberEl.error = null
      this.expiryEl.error = null
      this.cvcEl.error = null
    }
  }
}
