import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthResponse, Credentials } from 'src/app/types/auth';
import { tap } from 'rxjs/operators';
import { NationalNumber } from 'libphonenumber-js/types';

export interface ChangePasswordPayload {
  current: string,
  password: string,
  password_confirmation: string,
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient
  ) { }

  login(credentials: Credentials) {
    return this.httpClient.post(`${environment.apiUrl}auth/login`, credentials)
  }

  register(user: any) {
    return this.httpClient.post(`${environment.apiUrl}auth/register`, user);
  }

  verifyCode(code: string) {
    return this.httpClient.post(`${environment.apiUrl}auth/phone/verification`, { code });
  }

  deleteAccount() {
    return this.httpClient.delete(`${environment.apiUrl}me/account`);
  }

  sendCode(phone: NationalNumber, indicator: string) {
    return this.httpClient.post(`${environment.apiUrl}auth/phone/send`, { phone, indicator });
  }

  logout(): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}auth/logout`, {});
  }

  updateProfile(data: any) {
    return this.httpClient.put(`${environment.apiUrl}me/profile`, data);
  }

  forgotPassword(data: any) {
    return this.httpClient.post(`${environment.apiUrl}auth/forgot-password`, data);
  }

  changePassword(data: ChangePasswordPayload) {
    return this.httpClient.put(`${environment.apiUrl}me/change-password`, data)
  }
}
