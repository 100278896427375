import { createAction, props } from "@ngrx/store";
import { LocalizationResponse } from "src/app/types/global";

export const SET_LOCALIZATION = '[Localization] Set Localization';
export const SET_LOCALIZATION_SUCCESS = '[Localization] Set Localization Success';
export const SET_LOCALIZATION_FAILURE = '[Localization] Set Localization Failure';

export const SET_LOCALIZATION_USER = '[Localization] Set Localization User';
export const SET_LOCALIZATION_USER_SUCCESS = '[Localization] Set Localization User Success';
export const SET_LOCALIZATION_USER_FAILURE = '[Localization] Set Localization User Failure';

export const GET_LOCALIZATION = '[Localization] Get Localization';
export const GET_LOCALIZATION_SUCCESS = '[Localization] Get Localization Success';
export const GET_LOCALIZATION_FAILURE = '[Localization] Get Localization Failure';

export const GET_LOCALIZATION_USER = '[Localization] Get Localization User';
export const GET_LOCALIZATION_USER_SUCCESS = '[Localization] Get Localization User Success';
export const GET_LOCALIZATION_USER_FAILURE = '[Localization] Get Localization User Failure';

export const GET_LOCALIZATION_LIST = '[Localization] Get Localization List';
export const GET_LOCALIZATION_LIST_SUCCESS = '[Localization] Get Localization List Success';
export const GET_LOCALIZATION_LIST_FAILURE = '[Localization] Get Localization List Failure';

export interface LocalizationState {
    localization: any;
    localizationList: Array<string>;
    locale: string;
    error: any;
}

export const setLocalization = createAction(
    SET_LOCALIZATION,
    props<{lang: string}>()
);

export const setLocalizationSuccess = createAction(
    SET_LOCALIZATION_SUCCESS,
    props<{localization: LocalizationResponse}>()
)

export const setLocalizationFailure = createAction(
    SET_LOCALIZATION_FAILURE,
    props<{error: any}>()
)

export const setLocalizationUser = createAction(
    SET_LOCALIZATION_USER,
    props<{lang: string}>()
);

export const setLocalizationUserSuccess = createAction(
    SET_LOCALIZATION_USER_SUCCESS,
    props<{localization: LocalizationResponse}>()
)

export const setLocalizationUserFailure = createAction(
    SET_LOCALIZATION_USER_FAILURE,
    props<{error: any}>()
)

export const getLocalization = createAction(
    GET_LOCALIZATION
);

export const getLocalizationSuccess = createAction(
    GET_LOCALIZATION_SUCCESS,
    props<{localization: LocalizationResponse}>()
);

export const getLocalizationFailure = createAction(
    GET_LOCALIZATION_FAILURE,
    props<{error: any}>()
);

export const getLocalizationUser = createAction(
    GET_LOCALIZATION_USER
);

export const getLocalizationUserSuccess = createAction(
    GET_LOCALIZATION_USER_SUCCESS,
    props<{localization: LocalizationResponse}>()
);

export const getLocalizationUserFailure = createAction(
    GET_LOCALIZATION_USER_FAILURE,
    props<{error: any}>()
);

export const getLocalizationList = createAction(
    GET_LOCALIZATION_LIST
);

export const getLocalizationListSuccess = createAction(
    GET_LOCALIZATION_LIST_SUCCESS,
    props<{localizationList: Array<string>}>()
);

export const getLocalizationListFailure = createAction(
    GET_LOCALIZATION_LIST_FAILURE,
    props<{error: any}>()
);