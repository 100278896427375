import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Store } from '@ngrx/store';
import { clearRide } from 'src/app/store/actions/ride.actions';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { Booking } from 'src/app/types/booking';
import { selectCurrentBooking } from 'src/app/store/selectors/ride.selectors';

@Component({
  selector: 'kabin-ride-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
})
export class CompletedComponent  implements OnInit {

  booking$: Observable<Booking>;
  podType$: Observable<string>;
  checkinContinue$: Observable<string>;

  constructor(
    private store: Store
  ) {
    this.booking$ = this.store.select(selectCurrentBooking)
    this.podType$ = this.booking$.pipe(map(b => b.pod?.type));
    this.checkinContinue$ = this.booking$.pipe(map(b => b.checkin?.continue));
  }

  ngOnInit(
  ) {}

  async openInBrowser(url: string) {
    await Browser.open({ url });
  }

  handleClose() {
    this.store.dispatch(clearRide())
  }
}
