import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Booking } from 'src/app/types/booking';
import { getYear, getMonth } from 'date-fns'
import { groupBy, reverse } from 'lodash';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { selectLocale, selectLocalization } from 'src/app/store/selectors/localization.selectors';
import { LocalizationState } from 'src/app/store/actions/localization.actions';
import { Observable, of } from 'rxjs';
import { default as months } from 'src/app/utils/months.json';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'kabin-booking-list-month',
  templateUrl: './booking-list-month.component.html',
  styleUrls: ['./booking-list-month.component.scss'],
})
export class BookingListMonthComponent implements OnInit, OnChanges {

  @Input() list: Array<Booking>;
  @Input() from: string = 'next';
  public groupedList: Array<any> = [];
  public allMonth$: Observable<Array<string>>;

  constructor(
    public modalCtrl: ModalController,
    public store: Store,
    public router: Router,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit() {
    this.groupByMonths()
    this.allMonth$ = of(months[this.translocoService.getActiveLang()])
  }

  ngOnChanges() {
    this.groupByMonths()
  }

  groupByMonths() {

    this.groupedList = []
    if (this.list?.length > 0) {
      let groupPerYearAndMonth = groupBy(this.list, (item: Booking) => { return getYear(new Date(item.booking_start_at)) });
      let yearsFound = Object.keys(groupPerYearAndMonth);
      yearsFound.forEach((year: string) => {
        groupPerYearAndMonth[year] = groupBy(groupPerYearAndMonth[year], (month: Booking) => {
          return getMonth(new Date(month.booking_start_at));
        })
      })
      for (let item in groupPerYearAndMonth) {
        this.groupedList.push({ year: item, months: [] })
      }
      this.groupedList.forEach((year, yearIndex) => {
        let months = Object.keys(groupPerYearAndMonth[year.year]);
        if (this.from === 'past') {
          months = reverse(months);
        }
        months.forEach((month, monthIndex) => {
          this.groupedList[yearIndex].months.push({
            month,
            items: groupPerYearAndMonth[year.year][month]
          })
        })
      })

      if (this.from === 'past') {
        this.groupedList = reverse(this.groupedList);
      }
    }

  }

  async openMonthSelected(items: Array<Booking>, month: string, year: string, from: string) {
    this.router.navigate([`month/${year}/${month}/${from}`])
    // const modal = await this.modalCtrl.create({
    //   component: BookingListPage,
    //   cssClass: 'modal-booking-list',
    //   componentProps: {
    //     month,
    //     year,
    //     bookings: items,
    //     from
    //   }
    // })

    // modal.onDidDismiss().then(data => {
    //   if (data?.data?.dismissed) {
    //     this.modalCtrl.dismiss({dismissed: true})
    //   }
    // })

    // return modal.present();
  }

}
