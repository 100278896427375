import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectPaymentMethods } from 'src/app/store/selectors/me.selector';
import { PaymentMethod } from 'src/app/types/payment';
import { setSelectedPaymentMethod } from 'src/app/store/actions/booking.actions';
import { selectSelectedPaymentMethod } from 'src/app/store/selectors/booking.selectors';


@Component({
  selector: 'kabin-booking-payment-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent  implements OnInit {

  @Output() onClose = new EventEmitter<void>();
  @Output() openPaymentPage = new EventEmitter<void>();

  paymentMethods$: Observable<PaymentMethod[]>
  selectedPaymentMethod$: Observable<PaymentMethod>

  constructor(
    private store: Store,
  ) {
    this.paymentMethods$ = this.store.select(selectPaymentMethods)
    this.selectedPaymentMethod$ = this.store.select(selectSelectedPaymentMethod)
  }

  ngOnInit() {}

  selectPaymentMethod(method: PaymentMethod) {
    this.store.dispatch(setSelectedPaymentMethod({ selected: method }))
  }
}
