import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { extendBooking } from 'src/app/store/actions/ride.actions';
import { Booking } from 'src/app/types/booking';
import { differenceInMinutes, eachMinuteOfInterval, parseISO } from 'date-fns';
import { range as _range } from 'lodash' 
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ModalController } from '@ionic/angular';
import { ConfirmPage } from 'src/app/modals/confirm/confirm.page';
import { Confirm } from 'src/app/types/global';

@Component({
  selector: 'kabin-ride-ride-extension',
  templateUrl: './extension.component.html',
  styleUrls: ['./extension.component.scss'],
})
export class ExtensionComponent  implements OnInit {

  @Input() booking: Booking;
  amount = 5;
  minutes: Array<number> = [];

  constructor(
    private store: Store,
    private modalController: ModalController,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit() {
    const minutesUntilNextBooking = differenceInMinutes(parseISO(this.booking.expandableTo), parseISO(this.booking.dynamic_buffer_end_at))
    this.minutes = _range(5, minutesUntilNextBooking + 5, 5)
  }

  async extend() {
    this.translocoService.selectTranslate('ride.information.extend.confirm.body', { price: this.amount * this.booking.pod?.price_per_minute })
      .subscribe(async (value) => {
        const modal = await this.modalController.create({
          component: ConfirmPage,
          componentProps: {
            confirm: {
              title: 'ride.information.extend.confirm.title',
              description: value,
              icon: 'card-outline',
              iconBgColor: 'success',
            } as Confirm
          }
        })

        modal.onDidDismiss().then(({ data }: { data: any }) => {
          if (data.action) {
            this.store.dispatch(extendBooking({ amount: this.amount }))
          }
        });
        modal.present();
      })
  }
}
