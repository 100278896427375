import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  LOGIN,
  LOGIN_SUCCESS,
  GET_TOKEN,
  loginSuccess,
  loginFailure,
  getTokenSuccess,
  getTokenFailure,
  LOGOUT,
  GET_TOKEN_SUCCESS,
  getUser,
  GET_USER,
  getUserSuccess,
  getUserFailure,
  REGISTER,
  registerSuccess,
  registerFailure,
  REGISTER_SUCCESS,
  SEND_CODE,
  sendCodeSuccess,
  sendCodeFailure,
  VERIFY_CODE,
  verifyCodeSuccess,
  verifyCodeFailure,
  RESEND_CODE,
  resendCodeSuccess,
  resendCodeFailure,
  GET_USER_SUCCESS,
  DELETE_ACCOUNT,
  deleteAccountSuccess,
  deleteAccountFailure
} from '../actions/auth.actions';
import { AuthResponse, Credentials } from 'src/app/types/auth';
import { User } from 'src/app/types/auth';
import { AuthService } from 'src/app/services/auth.service';
import { selectAuthRegister, selectAuthRegisterCountry } from '../selectors/auth.selectors';
import { GetResult, Preferences } from '@capacitor/preferences';
import { FcmService } from 'src/app/services/fcm.service';
import { loadCurrentBooking, loadCurrentBookingFailure, loadCurrentBookingSuccess } from '../actions/ride.actions';
import { BookingService } from 'src/app/services/booking.service';
import { Booking } from 'src/app/types/booking';
import { loadNextBookings, loadPastBookings } from '../actions/me.actions';
import { MeService } from 'src/app/services/me.service';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOGIN),
      exhaustMap(({ email, password }: Credentials) => {
        return this.authService.login({ email, password }).pipe(
          map((response: AuthResponse) => loginSuccess({ ...response })),
          catchError(error => of(loginFailure({ error })))
        );
      })
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(REGISTER),
      withLatestFrom(this.store.select(selectAuthRegister)),
      exhaustMap(([data, { phone, country }]: any) => {
        return this.authService.register({ ...data, phone, indicator: country.indicator }).pipe(
          map((response: AuthResponse) => registerSuccess({ ...response })),
          catchError(error => of(registerFailure({ error })))
        );
      })
    )
  );

  // TODO: rework this effect
  onLoadUserCheckFcmToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        GET_USER_SUCCESS,
        LOGIN_SUCCESS,
        REGISTER_SUCCESS,
      ),
      tap(({ user }: { user: User, type: string }) => {
        const token = this.fcmService.fcmToken
        if (token && user.fcm_token !== token) {
          this.meService.updateProfile({ fcm_token: token }).subscribe((user: User) => {
            this.store.dispatch(getUserSuccess({ user }))
          }, err => {
            console.log(err);
          });
        }
      })
    )
  }, {
    dispatch: false
  })

  onLoadUserLoadAllRelatedBookings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        GET_USER_SUCCESS,
        LOGIN_SUCCESS,
      ),
      switchMap(() => [
        loadCurrentBooking(),
        loadNextBookings(),
      ])
    )
  );

  storeJwtWhenLoginOrRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LOGIN_SUCCESS,
        REGISTER_SUCCESS
      ),
      tap(({ access_token }: AuthResponse) => {
        Preferences.set({
          key: 'kabin-jwt',
          value: access_token,
        });
      })
    ), {
    dispatch: false
  });

  removeJwtOnLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOGOUT),
      tap(_ => {
        Preferences.remove({ key: 'kabin-jwt' });
      })
    ), {
    dispatch: false
  });

  getToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_TOKEN),
      exhaustMap(() => {
        return from(
          Preferences.get({ key: 'kabin-jwt' })
        ).pipe(
          map(({ value: token }: GetResult) => {
            if (!token) {
              throw new Error();
            }
            return token;
          }),
          map((token: string) => getTokenSuccess({ token })),
          catchError((error) => of(getTokenFailure({ error }))),
        );
      })
    )
  );

  loadUserWhenTokenIsFound$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_TOKEN_SUCCESS),
      map(() => getUser())
    )
  );

  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_USER),
      exhaustMap(() => {
        return this.meService.getProfile().pipe(
          map((user: User) => getUserSuccess({ user })),
          catchError(error => of(getUserFailure(error))),
        );
      })
    );
  });

  sendCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SEND_CODE),
      withLatestFrom(this.store.select(selectAuthRegisterCountry)),
      exhaustMap(([{ phone }, country]) => {
        return this.authService.sendCode(phone, country.indicator).pipe(
          map((message: string) => sendCodeSuccess()),
          catchError(error => of(sendCodeFailure({ error }))),
        );
      })
    );
  });

  resendCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RESEND_CODE),
      withLatestFrom(this.store.select(selectAuthRegister)),
      exhaustMap(([, { country, phone }]) => {
        return this.authService.sendCode(phone, country.indicator).pipe(
          map((message: string) => resendCodeSuccess()),
          catchError(error => of(resendCodeFailure({ error }))),
        );
      })
    );
  });

  verifyCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VERIFY_CODE),
      exhaustMap(({ code }) => {
        return this.authService.verifyCode(code).pipe(
          map((message: string) => verifyCodeSuccess()),
          catchError(error => of(verifyCodeFailure({ error }))),
        );
      })
    );
  });

  deleteAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DELETE_ACCOUNT),
      exhaustMap(() => {
        return this.authService.deleteAccount().pipe(
          map(() => deleteAccountSuccess()),
          catchError(error => of(deleteAccountFailure({ error }))),
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private meService: MeService,
    private store: Store,
    private fcmService: FcmService,
    private bookingService: BookingService,
  ) { }
}
