import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Booking } from 'src/app/types/booking';
import { ActionSheetController } from '@ionic/angular';
import { nanoid } from 'nanoid';
import Compressor from 'compressorjs';
import { ToastController } from '@ionic/angular';
import { Camera, CameraResultType } from '@capacitor/camera';
import { finalize } from 'rxjs/operators';
import { Picture } from 'src/app/types/global';
import { RideService } from 'src/app/services/ride.service';


@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})

export class FileUploadComponent implements OnInit {
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;

  @Input() booking: Booking;
  @Input() from: string;
  @Input() pictures: Array<Picture>;
  @Input() disabled: Boolean = false;
  @Output() newPictures = new EventEmitter<any>();
  loader: boolean = false;
  subtitle: string = null;

  constructor(
    private actionSheetController: ActionSheetController,
    private toastController: ToastController,
    private rideService: RideService,
  ) { }

  ngOnInit() {
    switch (this.from) {
      case 'checkin':
        this.subtitle = "checkin.photo";
        break;
      case 'checkout':
        this.subtitle = "checkout.photo";
        break;
      default:
        this.subtitle = "default.file_upload";
    }
  }

  async onFileSelected() {
    const buttons = [
      {
        text: 'Prendre une photo',
        icon: 'camera',
        handler: async () => {
          const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Base64
          });
          const filename = nanoid() + '.jpeg';
          this.urltoFile("data:image/jpeg;base64," + image.base64String, filename).then(file => {
            this.upload(file, filename)
          }).catch(err => {
            console.log('err file', err)
          });
        }
      },
      {
        text: 'Choisir dans la galerie',
        icon: 'image',
        handler: () => {
          this.fileUpload.nativeElement.click();
        }
      }
    ];

    const actionSheet = await this.actionSheetController.create({
      header: 'Selectionner',
      buttons
    })

    await actionSheet.present()
  }

  upload(file: File, filename: string) {
    this.loader = true;
    new Compressor(file, { quality: 0.4, success: (newImage) =>  {
      const formData = new FormData();
      formData.append('picture', new File([newImage], filename));
      const request = this.from === 'checkin' ?
        this.rideService.addCheckinPicture(this.booking, formData) :
        this.rideService.addCheckoutPicture(this.booking, formData)
      request.pipe(
        finalize(() => {
          this.loader = false;
        })
      ).subscribe((picture: Picture) => {
        picture.fileName = filename;
        this.newPictures.emit(picture);
      }, err => {
        this.toastController.create({
          message: 'Impossible de télécharger votre image, veuillez réessayer.',
          duration: 2000,
          color: 'danger',
        });
      });
    }, error: () => {
      this.toastController.create({
        message: 'Impossible de télécharger votre image, veuillez réessayer.',
        duration: 2000,
        color: 'danger',
      });
    }});
  }

  urltoFile(url: string, filename: string){
    return (fetch(url)
      .then(res => res.blob())
      .then(blob => new File([blob], filename,{ type: "image/jpeg" })))
  }

  uploadFileFromGalery(event) {
    const file: File = event.target.files[0];
    const filename = file.name;
    if(file) {
      this.upload(file, filename)
    }
  }
}
