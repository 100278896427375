import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Booking } from 'src/app/types/booking';
import { BillingAddress, PaymentMethod } from 'src/app/types/payment';

export const LOAD_NEXT_BOOKINGS = '[Me] Load Next Bookings';
export const LOAD_NEXT_BOOKINGS_SUCCESS = '[Me] Load Next Bookings Success';
export const LOAD_NEXT_BOOKINGS_FAILURE = '[Me] Load Next Bookings Failure';

export const LOAD_PAST_BOOKINGS = '[Me] Load Past Bookings';
export const LOAD_PAST_BOOKINGS_SUCCESS = '[Me] Load Past Bookings Success';
export const LOAD_PAST_BOOKINGS_FAILURE = '[Me] Load Past Bookings Failure';

export const CANCEL_BOOKING = '[Me] Cancel Booking';
export const CANCEL_BOOKING_SUCCESS = '[Me] Cancel Booking Success';
export const CANCEL_BOOKING_FAILURE = '[Me] Cancel Booking Failure';

export const LOAD_PAYMENT_METHODS = '[Me] Load Payment Methods';
export const LOAD_PAYMENT_METHODS_SUCCESS = '[Me] Load Payment Methods Success';
export const LOAD_PAYMENT_METHODS_FAILURE = '[Me] Load Payment Methods Failure';

export const LOAD_BILLING_ADDRESSES = '[Me] Load Billing Addresses';
export const LOAD_BILLING_ADDRESSES_SUCCESS = '[Me] Load Billing Addresses Success';
export const LOAD_BILLING_ADDRESSES_FAILURE = '[Me] Load Billing Addresses Failure';


export const CLEAR_ME_ERROR = '[Me] Clear Me Error';

export const loadNextBookings = createAction(
  LOAD_NEXT_BOOKINGS,
);

export const loadNextBookingsSuccess = createAction(
  LOAD_NEXT_BOOKINGS_SUCCESS,
  props<{
    bookings: Booking[],
  }>()
);

export const loadNextBookingsFailure = createAction(
  LOAD_NEXT_BOOKINGS_FAILURE,
  props<{
    error: HttpErrorResponse,
  }>()
);

export const loadPastBookings = createAction(
  LOAD_PAST_BOOKINGS,
);

export const loadPastBookingsSuccess = createAction(
  LOAD_PAST_BOOKINGS_SUCCESS,
  props<{
    bookings: Array<Booking>
  }>()
);

export const loadPastBookingsFailure = createAction(
  LOAD_PAST_BOOKINGS_FAILURE,
  props<{
    error: any
  }>()
);

export const cancelBooking = createAction(
  CANCEL_BOOKING,
  props<{
    booking: Booking
  }>()
);

export const cancelBookingSuccess = createAction(
  CANCEL_BOOKING_SUCCESS,
  props<{
    booking: Booking
  }>()
);

export const cancelBookingFailure = createAction(
  CANCEL_BOOKING_FAILURE,
  props<{
    error: any
  }>()
);

export const loadPaymentMethods = createAction(
  LOAD_PAYMENT_METHODS
);

export const loadPaymentMethodsSuccess = createAction(
  LOAD_PAYMENT_METHODS_SUCCESS,
  props<{ paymentMethods: PaymentMethod[] }>()
);

export const loadPaymentMethodsFailure = createAction(
  LOAD_PAYMENT_METHODS_FAILURE,
  props<{ error: any }>()
);

export const loadBillingAddresses = createAction(
  LOAD_BILLING_ADDRESSES
);

export const loadBillingAddressesSuccess = createAction(
  LOAD_BILLING_ADDRESSES_SUCCESS,
  props<{ billingAddresses: BillingAddress[] }>()
);

export const loadBillingAddressesFailure = createAction(
  LOAD_BILLING_ADDRESSES_FAILURE,
  props<{ error: any }>()
);

export const clearMeError = createAction(
  CLEAR_ME_ERROR,
);