import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, concatMap, withLatestFrom, tap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { PodService } from "src/app/services/pod.service";
import {
  LOAD_PODS,
  TOGGLE_POD_DISPLAY,
  loadPods,
  loadPodsFailure,
  loadPodsSuccess,
} from "../actions/pod.actions";
import { Pod } from 'src/app/types/pod';
import { selectPodDisplay } from '../selectors/pod.selectors';
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class PodEffects {
  loadPods$ = createEffect(() => 
    this.actions$.pipe(
      ofType(LOAD_PODS),
      withLatestFrom(this.store.select(selectPodDisplay)),
      exhaustMap(([, podDisplay]) => {
        return this.podService.list(podDisplay === 'now' ? true : false).pipe(
          map((pods: Pod[]) => loadPodsSuccess({ pods })),
          catchError((error: HttpErrorResponse) => of(loadPodsFailure({ error }))),
        )
      })
    )
  );

  togglePodDisplay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TOGGLE_POD_DISPLAY),
      map(() => loadPods())
    )
  );

  constructor(
    private actions$: Actions,
    private podService: PodService,
    private store: Store,
  ) { }
}