import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { PusherService } from './services/pusher.service';
import type { User } from './types/auth';
import type { Page } from './types/global';
import type { NotificationOptions } from './types/global';
import { FcmService } from './services/fcm.service';
import { NotificationService } from './services/notification.service';
import { VersionPage } from './modals/version/version.page';
import { Store } from '@ngrx/store';
import { selectConnectionStatus, selectInitialLoginAttempted } from './store/selectors/auth.selectors';
import { getToken } from './store/actions/auth.actions';
import { getLocalization, getLocalizationList, getLocalizationUser, LocalizationState, setLocalization } from './store/actions/localization.actions';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { LocalizationService } from './services/localization.service';
import { selectLocalization, selectLocale, selectLocalizationList } from './store/selectors/localization.selectors';
import { Observable } from 'rxjs';
import { register } from 'swiper/element/bundle';
import { TranslocoService, getBrowserLang, TRANSLOCO_LOADING_TEMPLATE } from '@ngneat/transloco';
import { filter, pluck } from 'rxjs/operators';
import { GlobalService } from './services/global.service';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { fr, enUS } from 'date-fns/locale';
import { DateFnsConfigurationService } from 'ngx-date-fns';
register();

@Component({
  selector: 'kabin-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_LOADING_TEMPLATE,
      useValue: LoaderComponent
    }
  ]
})
export class AppComponent implements OnInit {

  appPages: Page[] = [];
  appPagesAnonymous: Page[] = [
    {
      title: 'Connexion',
      name: 'login',
      url: '/login'
    },
    {
      title: 'Inscription',
      name: 'register',
      url: '/register'
    },
  ];
  appPagesUser: Page[] = [
    {
      title: 'Réserver une Kabin',
      name: 'index',
      url: '/index',
    },
    {
      title: 'Mes réservations',
      name: 'bookings',
      url: '/bookings',
    },
    {
      title: 'Moyens de paiement',
      name: 'payment',
      url: '/payment'
    },
    {
      title: 'Adresses de facturation',
      name: 'billing',
      url: '/billing'
    },
    {
      title: 'Mon compte',
      name: 'account',
      url: '/account',
    },
  ];
  appVersion = '';
  isLoggedIn = false;
  isNotificationVisible = true;
  notification: NotificationOptions;
  user: User;
  lang: string = 'fr';
  translationLoaded = false;
  isNative: boolean = false;

  constructor(
    private platform: Platform,
    private router: Router,
    // private firebaseX: FirebaseX,
    private menuController: MenuController,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private fcmService: FcmService,
    private notificationService: NotificationService,
    private pusherService: PusherService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private localizationService: LocalizationService,
    private translocoService: TranslocoService,
    private globalService: GlobalService,
    private dateFnsConfigurationService: DateFnsConfigurationService,
  ) {
    console.log('INIT')
    if (Capacitor.isNativePlatform()) {
      this.fcmService.register();
    }
    this.initializeApp();
    this.store.dispatch(getToken());
    this.store.dispatch(getLocalizationList());
    this.store.select(selectInitialLoginAttempted).subscribe(({ user, isReady }) => {
      this.user = user
      if (isReady) {
        if (user) {
          this.translocoService.setActiveLang(user.localization);
          dateFnsConfigurationService.setLocale(this.test(user.localization))
        } else {
          this.translocoService.setActiveLang(getBrowserLang());
          dateFnsConfigurationService.setLocale(this.test(getBrowserLang()))
        }
      }
    });
    this.store.select(selectConnectionStatus).subscribe((isConnected: boolean) => {
      this.appPages = isConnected ? this.appPagesUser : this.appPagesAnonymous;
    })

    this.router.events.subscribe((event:any)=>{
      if(event instanceof NavigationStart){
        this.notificationService.setVisibility(false);
      }
    })
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.getPlatform() === 'android') {
        StatusBar.setOverlaysWebView({ overlay: true });
        StatusBar.setBackgroundColor({ color: '#fff' });
        StatusBar.setStyle({ style: Style.Light });
      }

      this.appVersion = environment.frontVersion
      SplashScreen.hide();

      if (!this.platform.is('desktop')) {
        this.fcmService.getToken()
        if (Capacitor.isNativePlatform()) {
          this.isNative = true;
        }
        // initialize Firebase Analytics
        if (environment.production) {
          // TODO: Enable analytics in futur version with this plugin : https://github.com/capacitor-community/firebase-analytics
          // this.firebaseX.setAnalyticsCollectionEnabled(true);
          // this.firebaseX.logEvent('test', { content_type: 'page_view' });
          // get Firebase FCM Token
          // @todo: save the token server-side and use it to push notifications to this device
        } else {
          // this.firebaseX.setAnalyticsCollectionEnabled(false);
        }
      }
    });
  }

  async ngOnInit() {
    this.checkVersion();

    this.notificationService.getVisibility().subscribe((isVisible: boolean) => {
      this.isNotificationVisible = isVisible;
      this.cdr.detectChanges();
    });

    this.translocoService.events$.pipe(
        filter(e => e.type === 'translationLoadSuccess'),
        pluck('payload')
      ).subscribe(({ langName, scope }) => {
        this.translationLoaded = true;
      });

      this.translocoService.events$.pipe(
        filter(e => e.type === 'translationLoadFailure'),
        pluck('payload')
      ).subscribe(({ langName, scope }) => {
        console.log('translationLoadFailure')
        this.translationLoaded = true;
        this.notificationService.notify({
          title: 'Translation error',
          body: 'Translations download failed. Please refresh the app.',
          type: 'danger',
        });
      });
  }

  async checkVersion(reopened: boolean = false) {
    this.globalService.version().subscribe(async (response) => {
      if (response > environment.frontVersion) {
        const versionModal = await this.modalCtrl.create({
          component: VersionPage,
          swipeToClose: false,
          backdropDismiss: false,
          showBackdrop: true,
          componentProps: {
            reopened
          }
        });

        versionModal.onDidDismiss().then(data => {
          this.checkVersion(true)
        });

        return versionModal.present();
      }
    })
  }

  /**
   * Menu: Opens page
   */
  goToPage(page: Page): void {
    this.router.navigate([page.url]);
    this.menuController.close();
  }

  test(lang: string) {
    switch(lang) {
      case 'fr': 
        return fr
      case 'en':
        return enUS
      default:
        return fr
    }
  }
}
