import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PopoverController, ModalController } from '@ionic/angular';
import { PasswordPopoverComponent } from 'src/app/components/password-popover/password-popover.component'
import { Store } from '@ngrx/store';
import { register } from 'src/app/store/actions/auth.actions';
import { selectAuthError, selectAuthLoading, selectAuthRegisterCountry, selectAuthRegisterPhone, selectAuthRegisterStep, selectConnectionStatus } from 'src/app/store/selectors/auth.selectors';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NationalNumber } from 'libphonenumber-js/types';
import { Country } from 'src/app/store/reducers/auth.reducer';
import { default as months } from 'src/app/utils/months.json';
import { default as shortMonths } from 'src/app/utils/short-months.json';
import { selectLocale } from 'src/app/store/selectors/localization.selectors';
import { subYears } from 'date-fns';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ConfirmPage } from 'src/app/modals/confirm/confirm.page';

@Component({
  selector: 'kabin-register-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent  implements OnInit {

  hidePassword: boolean = true;
  hidePasswordConfirmation: boolean = true;
  monthNames: Array<string> = months.fr
  monthShortNames: Array<string> = shortMonths.fr;

  minDate: any = subYears(new Date(), 18).toISOString().substring(0, 10);

  formRegister: UntypedFormGroup = this.formBuilder.group({
    firstname: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z-\s\u00C0-\u00FF]*$/),
      Validators.maxLength(20)
    ]],
    lastname: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z-\s\u00C0-\u00FF]*$/),
      Validators.maxLength(20)
    ]],
    date_of_birth: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [
      Validators.required,
      Validators.pattern(/^(?=.{5,})(?=.*[A-Z]).*$/)
    ]],
    password_confirmation: ['', Validators.required],
    has_newsletter: [false],
    has_cmi: [false],
    optin_terms: [false, [
      Validators.requiredTrue,
    ]],
    is_pmr: [false]
  });

  loading$: Observable<boolean>
  error$: Observable<HttpErrorResponse>;
  phone$: Observable<NationalNumber>;
  country$: Observable<Country>;

  passwordStrength: string;

  constructor(
    private popoverCtrl: PopoverController,
    public formBuilder: UntypedFormBuilder,
    private store: Store,
    public router: Router,
    public modalCtrl: ModalController,
  ) {
    this.error$ = this.store.select(selectAuthError);
    this.loading$ = this.store.select(selectAuthLoading);
    this.phone$ = this.store.select(selectAuthRegisterPhone);
    this.country$ = this.store.select(selectAuthRegisterCountry);
    this.store.select(selectLocale).subscribe(locale => {
      this.monthNames = months[locale]
      this.monthShortNames = shortMonths[locale]
    })
  }

  ngOnInit() {
    this.formRegister.setValidators(this.passwordsMatchValidator);
  }

  private passwordsMatchValidator = (form: UntypedFormGroup) => {
    if (form.get('password').value && form.get('password_confirmation').value) {
      return form.get('password').value === form.get('password_confirmation').value ? null : { mismatch: true };
    }
    return null;
  }

  getFormErrors() {
    return this.formRegister.controls;
  }

  getDate(e) {
    const date = new Date(e.target.value).toISOString().substring(0, 10)
    this.formRegister.get('date_of_birth').setValue(date, {
      onlyself: true
    })
  }

  async showPasswordPopover(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: PasswordPopoverComponent,
      cssClass: 'popover-fakemodal',
      event: ev
    });
    return popover.present();
  }

  async submitForm() {
    if (!this.formRegister.value.is_pmr) {
      this.register();
    } else {
      const modal = await this.modalCtrl.create({
        component: ConfirmPage,
        componentProps: {
          confirm: {
            title: "register.confirm.title",
            description: "register.confirm.subtitle",
            icon: 'alert-outline',
          }
        }
      })

      modal.onDidDismiss().then(({ data }: { data: any }) => {
        if (!data.action) {
          this.formRegister.controls['is_pmr'].setValue(false);
        }
        this.register();
      });
      modal.present();
    }
  }

  register() {
    this.store.dispatch(register(this.formRegister.value));
    // this.store.select(selectAuthRegisterStep).subscribe(registerStep => {
    //   if (registerStep === 'success') {
    //     this.router.navigate(['register/welcome'], { replaceUrl: true })
    //   }
    // })
  }

  hideAlert(event: any, prop: string) {
    if (event) this[prop] = null
  }

  async openInBrowser(url: string) {
    await Browser.open({ url });
  }
}
