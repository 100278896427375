import { Component, Input, OnInit } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'kabin-version',
  templateUrl: './version.page.html',
  styleUrls: ['./version.page.scss'],
})
export class VersionPage implements OnInit {

  @Input() reopened = false;

  public versionUrl = '';

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
  ) { }

  ngOnInit() {
    if(Capacitor.getPlatform() === 'ios') {
      this.versionUrl = 'https://apps.apple.com/fr/app/kabin/id1548102293';
    }
    if(Capacitor.getPlatform() === 'android') {
      this.versionUrl = 'https://play.google.com/store/apps/details?id=com.gravity.kabin&referrer=utm_source%3DKabin-website%26utm_medium%3Dcpc%26utm_campaign%3DKabin-Website-PlayStore%26anid%3Dadmob';
    }
    if(Capacitor.getPlatform() === 'web') {
      this.versionUrl = 'https://www.kabin.fr';
    }
  }
  async updateClicked() {
    await Browser.open({ url: this.versionUrl });
    this.modalCtrl.dismiss({ dismissed: true })
  }
}
