// Angular
import { NgModule, APP_INITIALIZER } from '@angular/core';
import {
  BrowserModule,
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// 3rd parties
import * as Hammer from 'hammerjs';
import { DateFnsModule, DateFnsConfigurationService } from 'ngx-date-fns';
import { fr } from 'date-fns/locale';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

// Kabin
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { ModalsModule } from './modals/modals.module';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InterceptorProviders } from './interceptors/interceptors';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/effects/auth.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { BookingEffects } from './store/effects/booking.effects';
import { LocalizationEffects } from './store/effects/localization.effects';
import { RideEffects } from './store/effects/ride.effects';
import { PodEffects } from './store/effects/pod.effects';
import { TranslocoRootModule } from './transloco-root.module';
import { MeEffects } from './store/effects/me.effects';

const frenchConfig = new DateFnsConfigurationService();
frenchConfig.setLocale(fr);

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      threshold: 2
    },
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

// Note we need a separate function as it's required
// by the AOT compiler.
const playerFactory = () => player;
const initializeAppFactory = (translocoService: TranslocoService) => {
  return () => translocoService.load(translocoService.getDefaultLang()).toPromise();
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios',
            swipeBackEnabled: false,
        }),
        HttpClientModule,
        AppRoutingModule,
        DragDropModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        DirectivesModule,
        ModalsModule,
        DateFnsModule.forRoot(),
        LottieModule.forRoot({ player: playerFactory }),
        HammerModule,
        StoreModule.forRoot(reducers, {
            metaReducers: metaReducers
        }),
        EffectsModule.forRoot([
          AuthEffects,
          BookingEffects,
          LocalizationEffects,
          RideEffects,
          PodEffects,
          MeEffects,
        ]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        TranslocoRootModule,
    ],
    providers: [
        InterceptorProviders,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: DateFnsConfigurationService, useValue: frenchConfig },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        },
        {
          provide: APP_INITIALIZER,
          useFactory: initializeAppFactory,
          multi: true,
          deps: [TranslocoService],
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
