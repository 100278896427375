import { HttpErrorResponse } from "@angular/common/http";
import { createReducer, on } from "@ngrx/store";
import { AuthResponse } from "src/app/types/auth";
import { User } from "src/app/types/auth";
import {
  login,
  loginSuccess,
  loginFailure,
  registerFailure,
  getTokenSuccess,
  getTokenFailure,
  getUserSuccess,
  getUserFailure,
  logout,
  registerSuccess,
  registerSelectCountry,
  sendCodeSuccess,
  verifyCodeSuccess,
  sendCodeFailure,
  verifyCodeFailure,
  sendCode,
  resetRegistration,
  deleteAccountFailure,
  clearAuthError,
  setAuthError,
  register,
} from 'src/app/store/actions/auth.actions';
import { CountryCode, getCountryCallingCode, NationalNumber } from 'libphonenumber-js/max';

export interface Country {
  label: string,
  code: CountryCode,
  indicator: string,
}
export interface AuthState {
  isConnected: boolean,
  token: string,
  user: User,
  error: any,
  errors: {
    login: HttpErrorResponse,
    register: HttpErrorResponse,
  },
  loading: boolean,
  register: {
    step: string,
    country: Country,
    phone: NationalNumber,
  },
  initialLoginAttempted: boolean,
}

export const initialState: AuthState = {
  isConnected: false,
  token: '',
  user: null,
  errors: {
    login: null,
    register: null,
  },
  error: null,
  loading: false,
  register: {
    step: 'phone',
    country: {
      label: 'France',
      code: 'FR',
      indicator: '+33',
    },
    phone: null,
  },
  initialLoginAttempted: false,
};

const onFailure = (state: AuthState, { error }): AuthState => {
  return {
    ...state,
    error,
    loading: false,
  }
}

const onSuccess = (state: AuthState): AuthState => {
  return {
    ...state,
    error: null,
    errors: {
      login: null,
      register: null,
    },
    loading: false,
  }
}

const onLoading = (state: AuthState): AuthState => {
  return {
    ...state,
    error: null,
    errors: {
      login: null,
      register: null,
    },
    loading: true,
  }
}

const onRegisterSelectCountry = (state: AuthState, { country }) => {
  return {
    ...state,
    register: {
      ...state.register,
      country,
    }
  }
}

const onSendCodeSuccess = (state: AuthState) => {
  return {
    ...state,
    error: null,
    errors: {
      login: null,
      register: null,
    },
    register: {
      ...state.register,
      step: 'code',
    }
  }
}

const onVerifyCodeSuccess = (state: AuthState) => {
  return {
    ...state,
    error: null,
    errors: {
      login: null,
      register: null,
    },
    register: {
      ...state.register,
      step: 'form',
    }
  }
}


const onSendCode = (state: AuthState, { phone }: { phone: NationalNumber }) => {
  return {
    ...state,
    register: {
      ...state.register,
      phone,
    }
  }
}

const onResetRegistration = (state: AuthState): AuthState => {
  return {
    ...state,
    error: null,
    errors: {
      register: null,
      login: null,
    },
    register: {
      step: 'phone',
      country: {
        label: 'France',
        code: 'FR',
        indicator: '+33',
      },
      phone: null,
    }
  }
}


const onRegisterSuccess = (state: AuthState, { access_token, user }: AuthResponse) => {
  return {
    ...state,
    token: access_token,
    isConnected: true,
    user,
    errors: {
      ...state.errors,
      register: null
    },
    register: {
      ...state.register,
      step: 'success',
    }
  }
}

const onClearAuthError = (state: AuthState): AuthState => {
  return {
    ...state,
    error: null,
  }
}

const onLoginSuccess = (state: AuthState, { access_token, user }: AuthResponse): AuthState => {
  return {
    ...state,
    token: access_token,
    isConnected: true,
    initialLoginAttempted: true,
    user,
    loading: false,
  }
}

const onGetTokenSuccess = (state: AuthState, { token }): AuthState => {
  return {
    ...state,
    token
  }
}

const onGetUserSuccess = (state: AuthState, { user }): AuthState => {
  return {
    ...state,
    user,
    isConnected: true,
  }
}

const onLogout = (state: AuthState): AuthState => {
  return {
    ...state,
    isConnected: false,
    user: null
  }
}

const onSetAuthError = (state: AuthState, { error }: { error: HttpErrorResponse }): AuthState => {
  return {
    ...state,
    error,
  }
}

const onInitialLoginFailure = (state: AuthState): AuthState => {
  return {
    ...state,
    initialLoginAttempted: true,
  }
}

export const reducer = createReducer(
  initialState,
  on(loginSuccess, onLoginSuccess),
  on(getTokenSuccess, onGetTokenSuccess),
  on(getUserSuccess, onGetUserSuccess),
  on(logout, onLogout),
  on(registerSuccess, onRegisterSuccess),
  on(registerSelectCountry, onRegisterSelectCountry),
  on(sendCode, onSendCode),
  on(sendCodeSuccess, onSendCodeSuccess),
  on(verifyCodeSuccess, onVerifyCodeSuccess),
  on(resetRegistration, onResetRegistration),
  on(clearAuthError, onClearAuthError),
  on(setAuthError, onSetAuthError),
  on(
    login,
    register,
    onLoading
  ),
  on(
    registerSuccess,
    sendCodeSuccess,
    verifyCodeSuccess,
    onSuccess
  ),
  on(
    loginFailure,
    registerFailure,
    sendCodeFailure,
    verifyCodeFailure,
    deleteAccountFailure,
    onFailure
  ),
  on(
    loginFailure,
    getTokenFailure,
    getUserFailure,
    onInitialLoginFailure,
  ),
);