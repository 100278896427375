import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Booking, BookingCreatePayload } from '../types/booking';
import { Coupon } from '../types/payment';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PusherService } from './pusher.service';
import { PodStatus } from '../types/pod';
import { getPodStatusSuccess, setBookingStatus } from '../store/actions/ride.actions';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private pusherService: PusherService,
  ) { }

  one(booking: Booking): Observable<Booking> {
    return this.httpClient.get<Booking>(environment.apiUrl + `me/booking/${booking.uuid}`);
  }

  cancel(booking: Booking): Observable<string> {
    return this.httpClient.put<string>(`${environment.apiUrl}booking/${booking.uuid}/cancel`, {});
  }

  current(): Observable<{ booking: Booking}> {
    return this.httpClient.get<{ booking: Booking}>(`${environment.apiUrl}me/booking/current`).pipe(
      tap(({ booking }) => {
        if (booking !== null) {
          const podStatusChannel = this.pusherService.subscribe(`pod.${booking.pod.uuid}.status`)
          const bookingChannel = this.pusherService.subscribe(`booking.${booking.uuid}`)
          const bookingSatusChanged = this.pusherService.subscribe(`booking.${booking.uuid}.status`)
          podStatusChannel.bind('new-status', ({ status }: { status: PodStatus}) => {
            this.store.dispatch(getPodStatusSuccess({ status }))
          })
          bookingChannel.bind('booking-checkout', ({ booking }: { booking: Booking }) => {
            this.store.dispatch(setBookingStatus({ status: booking.status }))
          })
          bookingSatusChanged.bind('booking-status-changed', ({ status }: { status: string }) => {
            this.store.dispatch(setBookingStatus({ status }))
          })
        }
      })
    );
  }

  create(payload: BookingCreatePayload): Observable<{ booking: Booking }> {
    return this.httpClient.post<{ booking: Booking }>(`${environment.apiUrl}booking`, payload)
  }

  getDiscountCoupon(coupon: string): Observable<Coupon> {
    return this.httpClient.get<Coupon>(`${environment.apiUrl}booking/discount/${coupon.toUpperCase()}`)
  }
}
