import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import { default as countries } from 'src/app/utils/countries.json'

import { KeyValue } from '@angular/common';
import { Store } from '@ngrx/store';
import { MeService } from 'src/app/services/me.service';
import { loadBillingAddresses } from 'src/app/store/actions/me.actions';

@Component({
  selector: 'kabin-add',
  templateUrl: './add.page.html',
  styleUrls: ['./add.page.scss'],
})

export class AddPage implements OnInit {

  @Input() fromModal: boolean = false;

  loading = false;
  formBilling: UntypedFormGroup = this.formBuilder.group({
    name: ['', [Validators.maxLength(20)]],
    type: ['pro', [Validators.required]],
    line1: ['', [Validators.required]],
    postal_code: ['', [Validators.required]],
    city: ['', [Validators.required]],
    country: [''],
    country_name: [''],
  });
  error: string;
  selection: any = null;
  action: string = 'back';
  countries: {[key: string]: string} = countries.fr;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private navController: NavController,
    private router: Router,
    private modalCtrl: ModalController,
    private store: Store,
    private translocoService: TranslocoService,
    private meService: MeService,
  ) {
    if (translocoService.getActiveLang()) {
      this.countries = countries[translocoService.getActiveLang()]
    }
  }

  orderByCountryName(_left: KeyValue<string, string>, _right: KeyValue<string,string>): number {
    return _left.value < _right.value ? -1 : (_right.value < _left.value ? 1 : 0)
  }

  async ngOnInit() {
    if (this.fromModal) {
      this.action = 'dismiss'
    }
  }

  onClear(e: Event) {
    this.formBilling.controls['line1'].setValue(null);
    this.formBilling.controls['postal_code'].setValue(null);
    this.formBilling.controls['city'].setValue(null);
    this.formBilling.controls['country'].setValue(null);
    this.formBilling.controls['country_name'].setValue(null);
  }

  addBillingAddress() {
    this.loading = true;
    this.meService.addBillingAddress(this.formBilling.value).pipe(
      finalize(() => this.loading = false)
    ).subscribe(() => {
      this.goBack();
      this.store.dispatch(loadBillingAddresses())
    }, (err: HttpErrorResponse) => {
      this.error = err.error;
    });
  }

  goBack() {
    if (this.fromModal) {
      this.modalCtrl.dismiss({ dismissed: true });
    } else {
      this.navController.back();
    }
  }

  checkCountry() {
    this.formBilling.controls['country_name'].setValue(this.countries[this.formBilling.controls['country'].value])
  }
}
