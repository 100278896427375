import { createSelector } from "@ngrx/store";
import { AppState } from "..";
import { LocalizationState } from "../actions/localization.actions";

export const selectLocalizationState = (state: AppState) => state.localization;

export const selectLocalization = createSelector(
    selectLocalizationState,
    (state: LocalizationState) => state.localization
)

export const selectLocalizationList = createSelector(
    selectLocalizationState,
    (state: LocalizationState) => state.localizationList
);

export const selectLocale = createSelector(
    selectLocalizationState,
    (state: LocalizationState) => state.localization.language
)