import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { NationalNumber } from 'libphonenumber-js/types';
import { AuthResponse, Credentials } from 'src/app/types/auth';

export const LOGIN = '[Auth] Login';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAILURE = '[Auth] Login Failure';

export const REGISTER = '[Auth] Register';
export const REGISTER_SUCCESS = '[Auth] Register Success';
export const REGISTER_FAILURE = '[Auth] Register Failure';
export const REGISTER_SELECT_COUNTRY = '[Auth] Register Select Country';

export const GET_TOKEN = '[Auth] Get Token';
export const GET_TOKEN_SUCCESS = '[Auth] Get Token Success';
export const GET_TOKEN_FAILURE = '[Auth] Get Token Failure';

export const LOGOUT = '[Auth] Logout';
export const LOGOUT_SUCCESS = '[Auth] Logout Success';
export const LOGOUT_FAILURE = '[Auth] Logout Failure';

export const GET_USER = '[Auth] Get User';
export const GET_USER_SUCCESS = '[Auth] Get User Success';
export const GET_USER_FAILURE = '[Auth] Get User Failure';

export const SEND_CODE = '[Auth] Send Code';
export const SEND_CODE_SUCCESS = '[Auth] Send Code Success';
export const SEND_CODE_FAILURE = '[Auth] Send Code Failure';

export const RESEND_CODE = '[Auth] Resend Code';
export const RESEND_CODE_SUCCESS = '[Auth] Resend Code Success';
export const RESEND_CODE_FAILURE = '[Auth] Resend Code Failure';

export const VERIFY_CODE = '[Auth] Verify Code';
export const VERIFY_CODE_SUCCESS = '[Auth] Verify Code Success';
export const VERIFY_CODE_FAILURE = '[Auth] Verify Code Failure';

export const RESET_REGRISTRATION = '[Auth] Reset Registration';

export const DELETE_ACCOUNT = '[Auth] Delete Account';
export const DELETE_ACCOUNT_SUCCESS = '[Auth] Delete Account Success';
export const DELETE_ACCOUNT_FAILURE = '[Auth] Delete Account Failure';

export const CLEAR_AUTH_ERROR = '[Auth] Clear Auth Error';
export const SET_AUTH_ERROR = '[Auth] Set Auth Error';

export const login = createAction(
  LOGIN,
  props<Credentials>()
);

export const loginSuccess = createAction(
  LOGIN_SUCCESS,
  props<AuthResponse>()
);

export const loginFailure = createAction(
  LOGIN_FAILURE,
  props<{ error: HttpErrorResponse }>()
);

export const getToken = createAction(
  GET_TOKEN
);

export const getTokenSuccess = createAction(
  GET_TOKEN_SUCCESS,
  props<{ token: string}>()
);

export const getTokenFailure = createAction(
  GET_TOKEN_FAILURE,
  props<any>()
);

export const logout = createAction(
  LOGOUT
);

export const getUser = createAction(
  GET_USER
);

export const getUserSuccess = createAction(
  GET_USER_SUCCESS,
  props<any>()
);

export const getUserFailure = createAction(
  GET_USER_FAILURE,
  props<any>()
);

export const register = createAction(
  REGISTER,
  props<{
    firstname: string,
    lastname: string,
    date_of_birth: string,
    email: string,
    password: string,
    password_confirmation: string,
    has_newsletter: boolean,
    has_cmi: boolean,
    localization: string,
  }>()
);

export const registerSuccess = createAction(
  REGISTER_SUCCESS,
  props<AuthResponse>()
);

export const registerFailure = createAction(
  REGISTER_FAILURE,
  props<any>()
);

export const registerSelectCountry = createAction(
  REGISTER_SELECT_COUNTRY,
  props<{
    country: any,
  }>()
);

export const sendCode = createAction(
  SEND_CODE,
  props<{
    phone: NationalNumber,
  }>()
);

export const sendCodeSuccess = createAction(
  SEND_CODE_SUCCESS,
);

export const sendCodeFailure = createAction(
  SEND_CODE_FAILURE,
  props<{
    error: any
  }>()
);

export const resendCode = createAction(
  RESEND_CODE,
);

export const resendCodeSuccess = createAction(
  RESEND_CODE_SUCCESS,
);

export const resendCodeFailure = createAction(
  RESEND_CODE_FAILURE,
  props<{
    error: any
  }>()
);

export const verifyCode = createAction(
  VERIFY_CODE,
  props<{
    code: string,
  }>()
);

export const verifyCodeSuccess = createAction(
  VERIFY_CODE_SUCCESS,
);

export const verifyCodeFailure = createAction(
  VERIFY_CODE_FAILURE,
  props<{
    error: any
  }>()
);

export const resetRegistration = createAction(
  RESET_REGRISTRATION,
);

export const deleteAccount = createAction(
  DELETE_ACCOUNT,
);

export const deleteAccountSuccess = createAction(
  DELETE_ACCOUNT_SUCCESS,
);

export const deleteAccountFailure = createAction(
  DELETE_ACCOUNT_FAILURE,
  props<{
    error: any | HttpErrorResponse,
  }>()
);

export const clearAuthError = createAction(
  CLEAR_AUTH_ERROR
);

export const setAuthError = createAction(
  SET_AUTH_ERROR,
  props<{
    error: HttpErrorResponse,
  }>()
);